import React, { PropsWithChildren } from 'react';
import LoadingIndicator, { LoadingIndicatorProps } from './LoadingIndicator';

interface Props extends LoadingIndicatorProps {
  loading: boolean;
}

const Loader: React.FC<PropsWithChildren<Props>> = ({
  children,
  loading,
  className,
  ...props
}) => {
  if (loading) {
    return (
      <LoadingIndicator
        size="large"
        className={className ?? 'mx-auto'}
        {...props}
      />
    );
  }

  return <>{children}</>;
};

export default Loader;
