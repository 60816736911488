import {
  CreateItemFormValues,
  MAX_ITEM_TITLE,
} from '../components/ItemForms/CreateItemForm';
import { FullCategory, PostItemRequest } from '../api/intf/item';
import getAdditionalItemDetails from './getAdditionalItemDetails';
import generateItemTitle from './generateItemTitle';
import { QUALITY_DESCRIPTION } from '../constants/valueMatrix';
import { TradeInOrganization } from '../api/orgs';
import getQuoteListingMetadata from './getQuoteListingMetadata';
import { QueryClient } from 'react-query';

// Not proud of these weird throw things, but it fits the needs of the tool
export default function validateAndGetSubmittableListing(
  values: CreateItemFormValues,
  categoryItem: FullCategory,
  queryClient: QueryClient,
  organization?: TradeInOrganization,
) {
  if (values.quality !== 'unacceptable') {
    // Demand is required if there's no model version
    if (!values.demand && !values.modelVersionId) {
      throw {
        errors: [
          {
            field: 'demand',
            message: 'Required',
          },
        ],
      };
    }

    if (!values.resalePrice) {
      throw {
        errors: [
          {
            field: 'resalePrice',
            message: 'Required',
          },
        ],
      };
    }

    if (!values.cashoffer) {
      throw {
        errors: [
          {
            field: 'cashoffer',
            message: 'Required',
          },
        ],
      };
    }
  }

  const requestBody: PostItemRequest = {
    brand_id: values.brand && parseInt(values.brand.value, 10),
    cash_offer_amount: parseInt(values.cashoffer, 10) || 0,
    category_id: parseInt(values.category.value, 10),
    credit_offer_amount:
      parseInt(values.cashoffer, 10) *
        (1 + organization?.credit_offer_markup!) || 0,
    demand: values.demand,
    description:
      QUALITY_DESCRIPTION[values.quality!] + '\n\n' + values.description,
    details: getAdditionalItemDetails(values, categoryItem!),
    estimated_trade_in: Number(values.estimatedTradeIn) || 0,
    metadata: getQuoteListingMetadata(values.quality!, queryClient),
    model_id: values.model && parseInt(values.model.value, 10),
    mpn: values.mpn,
    name: (values.title || generateItemTitle(values, categoryItem!)).slice(
      0,
      MAX_ITEM_TITLE,
    ),
    quality: values.quality,
    quantity: Number(values.quantity),
    resale_amount: parseInt(values.resalePrice, 10) || 0,
    retail_price: values.retailPrice,
    model_version_id: values.modelVersionId,
  };

  return requestBody;
}
