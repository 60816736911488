import React, { useState } from 'react';
import Field from '../Form/Field';
import FieldLabel from '../Form/FieldLabel';
import Input from '../Form/Input';
import ModelVersionTile from '../DetailSelectionFlow/ModelVersionTile';
import { useQuery } from 'react-query';
import { fetchSearchTradeInModelVersions } from '../../api/item';
import useDebounceValue from '../../hooks/useDebounceValue';
import { TradeInModelVersion } from '../../api/intf/item';
import LoadingIndicator from '../Form/LoadingIndicator';

export default function EventToolModelVersionSearch({
  onSelect,
}: {
  onSelect: (modelVersion: TradeInModelVersion) => void;
}) {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounceValue(search, 500);

  const { data: searchResults, isFetching } = useQuery(
    ['model-version-search', debouncedSearch],
    () => fetchSearchTradeInModelVersions(debouncedSearch),
    {
      enabled: debouncedSearch.length > 0 && search.length > 0,
      keepPreviousData: true,
      staleTime: Infinity,
    },
  );
  const { data: cachedSearchResults } = useQuery<
    Awaited<ReturnType<typeof fetchSearchTradeInModelVersions>>
  >(['model-version-search', search], { enabled: search.length > 0 });

  const results = cachedSearchResults || searchResults;

  let content: React.ReactNode;
  if (results?.length === 0) {
    content = <div className="col-span-2 text-center">No results</div>;
  } else {
    content = results?.map(modelVersion => (
      <ModelVersionTile
        key={modelVersion.id}
        modelVersion={modelVersion}
        onSelect={onSelect}
      />
    ));
  }

  return (
    <>
      <Field>
        <FieldLabel>Search for a model number (bats only)</FieldLabel>
        <Input
          type="search"
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder="Ex: WTLBBO517"
          className="text-xl"
        />
      </Field>

      {search.length > 0 && (
        <div id="model-versions" className="mt-6 mb-8">
          <div className="mb-4 -mt-10 pt-10 text-xl font-semibold flex gap-2">
            <div>Select an Option</div>
            {isFetching && <LoadingIndicator />}
          </div>

          <div className="grid grid-cols-3 gap-4 md:grid-cols-4">{content}</div>
        </div>
      )}
    </>
  );
}
