import React, { useMemo, useState } from 'react';
import { FullCategory, TradeInModelVersion } from '../../api/intf/item';
import { useQuery } from 'react-query';
import { fetchTradeInModelVersions } from '../../api/item';
import Field from '../Form/Field';
import FieldLabel from '../Form/FieldLabel';
import { Button } from '../Form/Button';
import SimpleModal from '../Modal/SimpleModal';
import LoadingIndicator from '../Form/LoadingIndicator';
import { LargeModelVersionTile } from '../Partner/Audit/AuditModelVersionSelector';
import FormikDetailSelectField from '../Form/FormikSelect/FormikDetailSelectField';
import { SelectOption } from '../Form/FormikSelect/DetailSelect';
import Loader from '../Form/Loader';

export default function ModelVersionSelector({
  fullCategory,
  detailUuids,
  hasAModelVersionObjectInState,
  modelUuid,
  selectedModelVersionId,
  onChange,
}: {
  fullCategory?: FullCategory;
  modelUuid?: string;
  selectedModelVersionId?: string | null;
  detailUuids: string[];
  hasAModelVersionObjectInState?: boolean; // Used to determine if a full model version object is in state, rather than just an ID.
  onChange: (modelVersion: TradeInModelVersion | null) => void;
}) {
  const [modalOpen, setModalOpen] = useState(false);

  function onClose() {
    setModalOpen(false);
  }

  function handleChange(modelVersion: TradeInModelVersion | null) {
    onChange(modelVersion);
    onClose();
  }

  const { data: modelVersions, isLoading } = useQuery(
    ['model-versions', modelUuid, detailUuids] as const,
    () => fetchTradeInModelVersions(modelUuid!, detailUuids),
    {
      staleTime: Infinity,
      enabled: !!modelUuid,
      onSuccess: data => {
        const initialSelectedVersion = data.find(
          modelVersion => modelVersion.id === selectedModelVersionId,
        );

        if (initialSelectedVersion && !hasAModelVersionObjectInState) {
          handleChange(initialSelectedVersion);
        }
      },
    },
  );

  const selectedModelVersion = modelVersions?.find(
    modelVersion => modelVersion.id === selectedModelVersionId,
  );

  return (
    <>
      <Field>
        <FieldLabel>Model Version</FieldLabel>

        <Loader
          loading={!!selectedModelVersionId && isLoading}
          className="m-12"
        >
          {selectedModelVersion ? (
            <div className="flex items-start justify-between space-x-4">
              <div>
                <img
                  src={selectedModelVersion.primary_image?.small_url}
                  className="mb-4 w-40"
                />
                <div className="font-semibold">{selectedModelVersion.name}</div>
              </div>
              <Button variant="outlined" onClick={() => setModalOpen(true)}>
                Change
              </Button>
            </div>
          ) : (
            <Button
              variant="outlined"
              onClick={() => setModalOpen(true)}
              disabled={!modelUuid}
            >
              Select Model Version
            </Button>
          )}
        </Loader>
      </Field>

      <SimpleModal
        open={modalOpen}
        onClose={onClose}
        title="Select Model Version"
        className="w-full max-w-2xl"
      >
        <div className="mb-8 space-y-4 md:space-y-6">
          {fullCategory?.details
            ?.filter(d => d.trade_in_required)
            ?.map(detailGroup => (
              <FormikDetailSelectField
                key={detailGroup.legacy_id}
                name={detailGroup.slug || detailGroup.name}
                label={detailGroup.name}
                options={detailGroup.options.map(detail => {
                  if (Object.keys(detail).length === 0) {
                    return {
                      label: '',
                      value: '',
                      uuid: '',
                    };
                  }

                  return {
                    label: detail.name,
                    slug: detail.slug,
                    value: detail.legacy_id
                      ? detail.legacy_id.toString()
                      : detail.id.toString(),
                    value_guides_required: detailGroup.value_guides_required,
                    trade_in_required: detailGroup.trade_in_required,
                    uuid: detail.id,
                  } as SelectOption;
                })}
                isClearable={!detailGroup.required}
                required={detailGroup.required}
              />
            ))}
        </div>
        <div className="mb-8 space-y-4 md:space-y-6">
          {isLoading && (
            <LoadingIndicator size="large" className="my-8 mx-auto" />
          )}
          {modelVersions?.length === 0 ? (
            <div className="text-center text-gray-500">
              No model versions found
            </div>
          ) : (
            modelVersions?.map(modelVersion => (
              <LargeModelVersionTile
                key={modelVersion.id}
                modelVersion={modelVersion}
                selected={selectedModelVersion?.id === modelVersion.id}
                onSelect={handleChange}
              />
            ))
          )}
        </div>

        <div className="grid grid-cols-2 gap-4">
          <Button fluid variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <Button
            color="danger"
            onClick={() => handleChange(null)}
            disabled={!selectedModelVersion}
          >
            Unselect
          </Button>
        </div>
      </SimpleModal>
    </>
  );
}
