import React from 'react';
import {
  Field as BaseFormikField,
  FieldConfig,
  useField,
  useFormikContext,
} from 'formik';

import FieldError from '../FieldError';
import Field from '../Field';
import Select, { DetailSelectProps, SelectOption } from './DetailSelect';
import FieldLabel from '../FieldLabel';

type Props = Omit<FieldConfig, 'value'> &
  DetailSelectProps & {
    label?: string;
    labelRight?: React.ReactNode;
    isClearable?: boolean;
    options?: SelectOption[];
    setSelectedCategoryId?: (id: number) => void;
    required?: boolean;
  };

const FormikDetailSelectField: React.FC<Props> = ({
  children,
  isClearable,
  label,
  labelRight,
  name,
  setSelectedCategoryId,
  required,
  ...props
}) => {
  const [field, meta] = useField(name);
  const { values, setFieldValue } = useFormikContext<any>();

  const handleChange = (value: SelectOption) => {
    if (name === 'category' && setSelectedCategoryId) {
      setSelectedCategoryId(parseInt(value?.value, 10));
      setFieldValue('brand', null);
      setFieldValue('model', null);
      setFieldValue('modelVersion', null);
      setFieldValue('modelVersionId', null);
    } else if (name === 'brand') {
      setFieldValue('model', null);
      setFieldValue('modelVersion', null);
      setFieldValue('modelVersionId', null);
    } else if (name === 'model') {
      setFieldValue('modelVersion', null);
      setFieldValue('modelVersionId', null);
    }

    setFieldValue(name, value);
  };

  let trulyRequired = values.quality === 'unacceptable' ? false : required;
  return (
    <Field>
      <FieldLabel
        className="flex justify-between"
        htmlFor={name}
        error={!!meta.error}
      >
        <span>
          {label} {trulyRequired && <span className="text-red-600">*</span>}
        </span>
        {labelRight}
      </FieldLabel>
      <BaseFormikField
        as={Select}
        isClearable={isClearable}
        id={name}
        {...field}
        error={!!meta.error}
        value={values[name]}
        onChange={handleChange}
        required={trulyRequired}
        validate={(v: any) => {
          // Required but not present, but only validate if "quality" isn't "unacceptable"
          if (required && !values[name] && values.quality !== 'unacceptable') {
            return 'Required';
          }
          return false;
        }}
        {...props}
      >
        {children}
      </BaseFormikField>
      {meta.error && <FieldError id={name}>{meta.error}</FieldError>}
    </Field>
  );
};

export default FormikDetailSelectField;
