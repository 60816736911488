import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { FullCategory, TradeInModelVersion } from '../../api/intf/item';
import { postListingItem } from '../../api/item';
import { formatFormikError } from '../../api/ApiError';
import { SelectOption } from '../Form/FormikSelect/DetailSelect';
import { useEventCustomerQuoteQuery } from '../../hooks/routing/useEventCustomerQuoteQuery';
import { Demand, Quality } from '../../constants/valueMatrix';
import { useQueryClient } from 'react-query';
import qsStringify from '../../utils/qsStringify';
import validateAndGetSubmittableListing from '../../utils/validateAndGetSubmittableListing';
import { toast } from 'react-toastify';
import { TradeInOrganization } from '../../api/orgs';

interface Props extends React.FormHTMLAttributes<HTMLFormElement> {
  categoryItem: FullCategory | undefined;
  customerId: string;
  quoteId: string;
  categoryId: number;
  organization: TradeInOrganization | undefined;
  itemQuery?: any;
}

export interface CreateItemFormValues {
  category: SelectOption;
  cashoffer: string;
  resalePrice: string;
  estimatedTradeIn?: string;

  retailPrice?: string;
  description?: string;

  brand?: SelectOption;
  model?: SelectOption;

  modelVersionId?: string | null;
  modelVersion?: TradeInModelVersion;

  // Manually override estimated resale
  valueGuideInput?: number;
  quality?: Quality;
  demand?: Demand;
  quantity?: number;

  createAnother?: boolean;
  title?: string;

  mpn?: string;
}

export const MAX_ITEM_TITLE = 100;

export const itemFormSchema: yup.SchemaOf<any> = yup.object({
  category: yup.mixed(),
  quality: yup.string().required('Required'),
  demand: yup.string(),
  cashoffer: yup.string(),
  resalePrice: yup.string(),
  estimatedTradeIn: yup.string(),
  brand: yup.mixed().required('Required'),
  description: yup.string(),
  model: yup.mixed(),
  retailPrice: yup.string(),
  quantity: yup
    .number()
    .min(1, 'Quantity must be at least 1')
    .required('Required'),
  title: yup
    .string()
    .max(MAX_ITEM_TITLE, 'Title must be less than 100 characters'),
});

const initialValues: CreateItemFormValues = {
  category: { value: '', label: '' } as SelectOption,
  cashoffer: '',
  resalePrice: '',
  estimatedTradeIn: '',
  retailPrice: '',
  description: '',
  quantity: 1,
  mpn: '',
};

const CreateItemForm: React.FC<Props> = ({
  children,
  categoryItem,
  customerId,
  quoteId,
  categoryId,
  organization,
  itemQuery,
  ...props
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { eventId } = useEventCustomerQuoteQuery();
  const [initValues, setInitValues] = useState(initialValues);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let value: Partial<CreateItemFormValues> = {
      ...initialValues,
      createAnother: !!searchParams.get('create_another'),
      category: {
        label: categoryItem?.full_name ?? '',
        value: categoryItem?.id ? categoryItem.legacy_id.toString() : '',
        uuid: categoryItem?.id,
      },
      modelVersionId: searchParams.get('model_version_id'),
    };

    if (itemQuery && categoryItem) {
      const queryKeys = Object.keys(itemQuery);

      if (queryKeys.includes('brand')) {
        const brandItem = categoryItem.brands?.find(
          item => item.legacy_id === parseInt(itemQuery.brand[0]),
        );
        if (brandItem) {
          value['brand'] = {
            label: brandItem.name,
            value: brandItem.legacy_id.toString(),
            uuid: brandItem.id,
            slug: brandItem.slug,
          };
        } else {
          console.error(
            "Has a brand id in the URL but can't find a brand in the list",
          );
        }
      }

      if (queryKeys.includes('model')) {
        const modelItem = categoryItem.models?.find(
          item => item.legacy_id === parseInt(itemQuery.model[0]),
        );
        if (modelItem) {
          value['model'] = {
            label: modelItem.name,
            value: modelItem.legacy_id.toString(),
            uuid: modelItem.id,
            slug: modelItem.slug,
          };
        } else {
          console.error(
            "Has a model id in the URL but can't find a model in the list",
          );
        }
      }

      delete itemQuery['category'];
      delete itemQuery['brand'];
      delete itemQuery['model'];

      if (categoryItem.details && categoryItem.details.length > 0) {
        for (let index of queryKeys) {
          const foundDetailGroup = categoryItem.details.find(
            item => item.slug === index,
          );
          if (foundDetailGroup) {
            const foundDetailItem = foundDetailGroup.options.find(
              item => item.legacy_id === parseInt(itemQuery[index]),
            );
            if (foundDetailItem) {
              // @ts-ignore
              value[index] = {
                label: foundDetailItem.name,
                value: foundDetailItem.legacy_id.toString(),
                uuid: foundDetailItem.id,
                slug: foundDetailItem.slug,
              };
            } else {
              console.error(
                `Has a "${foundDetailGroup.slug}" detail in the URL but can't find a detail in the list`,
              );
            }
          }
        }
      }
    }

    // @ts-ignore
    setInitValues(value);
  }, [categoryItem]);

  const handleSubmit = async (
    values: CreateItemFormValues,
    { setErrors }: any,
  ) => {
    try {
      const requestBody = validateAndGetSubmittableListing(
        values,
        categoryItem!,
        queryClient,
        organization,
      );

      const listingResponse = await postListingItem(quoteId, requestBody);

      // Invalidate only quotes by ID, then invalidate everything
      await queryClient.invalidateQueries('quotes', {
        exact: false,
      });
      queryClient.invalidateQueries();

      if (values.createAnother) {
        const query = {
          mode: 'copy',
          categoryId: listingResponse.category_id,
          listingId: listingResponse.id,
          create_another: true,
        };
        toast.success('Listing created');
        const queryItem = qsStringify(query);
        navigate(
          `/event/${eventId}/customer/${customerId}/quote/${quoteId}/listing/create${queryItem}`,
        );
        window.scrollTo(0, 0);
      } else {
        navigate(
          `/event/${eventId}/customer/${customerId}/quote/${quoteId}/summary`,
        );
      }
    } catch (e) {
      setErrors(formatFormikError(e));
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      onSubmit={handleSubmit}
      validationSchema={itemFormSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <Form {...props}>{children}</Form>
    </Formik>
  );
};

export default CreateItemForm;
