import { ValueGuideMeta } from '../api/intf/item';

export function getSellThroughRate(valueGuide?: ValueGuideMeta): number {
  if (
    valueGuide?.recently_published_items != null &&
    valueGuide?.recently_published_items > 0
  ) {
    if (valueGuide?.available_items === 0 && (valueGuide?.recent_sales ?? 0) > 0) {
      return 100;
    }
    return Math.round(
      ((valueGuide?.recent_sales ?? 0) / valueGuide?.available_items) * 100,
    );
  } else {
    return 0;
  }
}
