export type TokenRole =
  | 'ROLE_USER'
  | 'ROLE_ORG_ADMIN'
  | 'ROLE_TRADE_IN_DISTRICT_MANAGER'
  | 'ROLE_TRADE_IN_STORE_MANAGER'
  | 'ROLE_TRADE_IN_STORE_EMPLOYEE'
  | 'ROLE_TRADE_IN_SUPPORT';
export const ROLE_USER: TokenRole = 'ROLE_USER';

export const ROLE_ORG_ADMIN: TokenRole = 'ROLE_ORG_ADMIN';

export const ROLE_TRADE_IN_DISTRICT_MANAGER: TokenRole =
  'ROLE_TRADE_IN_DISTRICT_MANAGER';

export const ROLE_TRADE_IN_STORE_MANAGER: TokenRole =
  'ROLE_TRADE_IN_STORE_MANAGER';

export const ROLE_TRADE_IN_STORE_EMPLOYEE: TokenRole =
  'ROLE_TRADE_IN_STORE_EMPLOYEE';

export const ROLE_TRADE_IN_SUPPORT: TokenRole = 'ROLE_TRADE_IN_SUPPORT';
