import { TradeInModelVersion } from '../api/intf/item';
import { Quality } from '../constants/valueMatrix';

/*
 * To summarize:
 *  - In-store items are quoted at "trade_cost_good"
 *  - Items can be audited where they use the "trade_cost_(quality)" for cash_offer_amount
 *  - Items will be listed where they use the "trade_list_(quality)" for resale_amount
 */

export function getTradeInValueForModelVersion(
  modelVersion?: TradeInModelVersion | null,
) {
  return (
    Number(
      modelVersion?.price_statistics?.trade_cost_very_good ||
        modelVersion?.price_statistics?.trade_cost_good,
    ) || 0
  );
}

export function getCashOfferAmountForModelVersionAndQuality(
  quality: Quality,
  modelVersion: TradeInModelVersion,
) {
  switch (quality) {
    case 'newWithTags':
      return (
        Number(modelVersion.price_statistics?.trade_cost_new_w_tags) ||
        Number(modelVersion.price_statistics?.trade_cost_new_no_tags) ||
        Number(modelVersion.price_statistics?.trade_cost_excellent) ||
        Number(modelVersion.price_statistics?.trade_cost_very_good) ||
        Number(modelVersion.price_statistics?.trade_cost_good) ||
        0
      );

    case 'newWithoutTags':
      return (
        Number(modelVersion.price_statistics?.trade_cost_new_no_tags) ||
        Number(modelVersion.price_statistics?.trade_cost_excellent) ||
        Number(modelVersion.price_statistics?.trade_cost_very_good) ||
        Number(modelVersion.price_statistics?.trade_cost_good) ||
        0
      );

    case 'excellent':
      return (
        Number(modelVersion.price_statistics?.trade_cost_excellent) ||
        Number(modelVersion.price_statistics?.trade_cost_very_good) ||
        Number(modelVersion.price_statistics?.trade_cost_good) ||
        0
      );

    case 'veryGood':
      return (
        Number(modelVersion.price_statistics?.trade_cost_very_good) ||
        Number(modelVersion.price_statistics?.trade_cost_good) ||
        0
      );

    case 'good':
    case 'damagedParts':
      return Number(modelVersion.price_statistics?.trade_cost_good) || 0;

    case 'unacceptable':
      return 0;
  }
}

export function getListPriceForModelVersionAndQuality(
  quality: Quality,
  modelVersion: TradeInModelVersion,
) {
  switch (quality) {
    case 'newWithTags':
      return (
        Number(modelVersion.price_statistics?.trade_list_new_w_tags) ||
        Number(modelVersion.price_statistics?.trade_list_new_no_tags) ||
        Number(modelVersion.price_statistics?.trade_list_excellent) ||
        Number(modelVersion.price_statistics?.trade_list_very_good) ||
        Number(modelVersion.price_statistics?.trade_list_good) ||
        0
      );

    case 'newWithoutTags':
      return (
        Number(modelVersion.price_statistics?.trade_list_new_no_tags) ||
        Number(modelVersion.price_statistics?.trade_list_excellent) ||
        Number(modelVersion.price_statistics?.trade_list_very_good) ||
        Number(modelVersion.price_statistics?.trade_list_good) ||
        0
      );

    case 'excellent':
      return (
        Number(modelVersion.price_statistics?.trade_list_excellent) ||
        Number(modelVersion.price_statistics?.trade_list_very_good) ||
        Number(modelVersion.price_statistics?.trade_list_good) ||
        0
      );

    case 'veryGood':
      return (
        Number(modelVersion.price_statistics?.trade_list_very_good) ||
        Number(modelVersion.price_statistics?.trade_list_good) ||
        0
      );

    case 'damagedParts':
    case 'good':
      return Number(modelVersion.price_statistics?.trade_list_good) || 0;

    case 'unacceptable':
      return 0;
  }
}
