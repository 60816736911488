import axios from 'axios';
import { ApiResponse } from './intf/generic';
import { RedeemInfo } from './intf/redeem';
import { getBaseUrl } from './axiosInitialization';

export interface TradeInReport {
  id: string;
  org_id: string;
  report_type: string;
  filename: string;
  file_path: string;
  metadata: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export const fetchPartnerReports = async () => {
  const { data } = await axios.get<ApiResponse<TradeInReport[]>>(
    `/trade-in/v1/reports`,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Failed to fetch quotes');
};

export const postDownloadReport = async (id: string) => {
  const { data } = await axios.get<Blob>(`/trade-in/v1/reports/${id}`, {
    baseURL: getBaseUrl('inventoryApi'),
    responseType: 'blob',
  });

  if (!data) {
    throw new Error('Failed to download report');
  }

  return data;
};
