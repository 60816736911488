import React, { SetStateAction, useEffect, useMemo } from 'react';
import FormikDetailSelectField from '../Form/FormikSelect/FormikDetailSelectField';
import CalculatorIcon from '../Icons/CalculatorIcon';
import CalculatorCheckbox from './CalculatorCheckbox';
import { SelectOption } from '../Form/FormikSelect/DetailSelect';
import {
  Category,
  FullCategory,
  TradeInModelVersion,
} from '../../api/intf/item';
import { TradeInOrganization } from '../../api/orgs';
import AuditModelVersionSelector from '../Partner/Audit/AuditModelVersionSelector';
import ModelVersionSelector from './ModelVersionSelector';
import { useFormikContext } from 'formik';
import { CreateItemFormValues } from './CreateItemForm';
import {
  getCashOfferAmountForModelVersionAndQuality,
  getListPriceForModelVersionAndQuality,
  getTradeInValueForModelVersion,
} from '../../utils/modelVersionValues';

interface Props {
  brandId?: string;
  categories: Category[] | undefined;
  fullCategory: FullCategory | undefined;
  setSelectedCategoryId: (id: number) => void;
  organization: TradeInOrganization | undefined;

  valueGuideEnabledFields: Record<string, boolean>;
  setValueGuideEnabledFields: React.Dispatch<
    SetStateAction<Record<string, boolean>>
  >;
}

interface AddCategorySelect {
  id?: string;
  name: string;
  label: string;
  required?: boolean;
  slug?: string;
  options: SelectOption[];
}

const ItemInfoFields: React.FC<Props> = ({
  brandId,
  categories,
  setSelectedCategoryId,
  fullCategory,
  setValueGuideEnabledFields,
  valueGuideEnabledFields,
}) => {
  const { values } = useFormikContext<CreateItemFormValues>();

  useEffect(() => {
    if (fullCategory?.details) {
      const enabledFields: Record<string, boolean> = {
        category: true,
        brand: true,
        model: true,
      };

      for (let i = 0; i < fullCategory.details?.length; i++) {
        const detail = fullCategory.details[i];
        if (detail.value_guides_required) {
          enabledFields[detail.slug || detail.name.toLowerCase()] = true;
        }
      }

      setValueGuideEnabledFields(enabledFields);
    }
  }, [fullCategory?.id]);

  // Category select options
  const categoryOptions: SelectOption[] = useMemo(() => {
    if (!categories || categories?.length === 0) {
      return [];
    }

    return categories
      ?.filter(c => c.relatable)
      .map(
        (category): SelectOption => ({
          label: category.full_name,
          slug: category.slug,
          value: category.legacy_id.toString(),
          value_guides_required: true,
          uuid: category.id,
        }),
      );
  }, [categories]);

  // Additional select options which are being changed by category select.
  const details: AddCategorySelect[] = useMemo(() => {
    if (!fullCategory) return [];

    let tmpOptions: AddCategorySelect[] = [];

    if (fullCategory.brands && fullCategory.brands.length) {
      const newBrandOption: AddCategorySelect = {
        name: 'brand',
        label: 'Brand',
        options: fullCategory.brands?.map((brand): SelectOption => {
          if (Object.keys(brand).length === 0) {
            return {
              label: '',
              value: '',
              slug: '',
              uuid: '',
            };
          }

          return {
            label: brand.name,
            slug: brand.slug,
            value: brand.legacy_id.toString(),
            value_guides_required: true,
            uuid: brand.id,
          };
        }),
      };

      tmpOptions.push(newBrandOption);
    } else {
      tmpOptions.push({
        name: 'brand',
        label: 'Brand',
        options: [],
      });
    }

    if (fullCategory.models && fullCategory.models.length) {
      const newModelOption: AddCategorySelect = {
        name: 'model',
        label: 'Model',
        options: fullCategory.models
          ?.filter(model =>
            brandId && model.legacy_brand_detail_id
              ? model.legacy_brand_detail_id == Number(brandId)
              : false,
          )
          .map<SelectOption>(model => {
            if (Object.keys(model).length === 0) {
              return {
                label: '',
                value: '',
                slug: '',
                uuid: '',
              };
            }

            return {
              label: model.name,
              slug: model.slug,
              uuid: model.id,
              value: model.legacy_id ? model.legacy_id.toString() : model.id,
              value_guides_required: true,
            };
          }),
      };

      tmpOptions.push(newModelOption);
    } else {
      tmpOptions.push({
        name: 'model',
        label: 'Model',
        options: [],
      });
    }

    if (fullCategory.details && fullCategory.details.length) {
      for (let detailGroup of fullCategory.details) {
        const newAddCategory: AddCategorySelect = {
          name: detailGroup.name,
          label: detailGroup.name,
          required: detailGroup.required,
          slug: detailGroup.slug,
          id: detailGroup.id,
          options: detailGroup.options.map(detail => {
            if (Object.keys(detail).length === 0) {
              return {
                label: '',
                value: '',
                uuid: '',
              };
            }

            return {
              label: detail.name,
              slug: detail.slug,
              value: detail.legacy_id
                ? detail.legacy_id.toString()
                : detail.id.toString(),
              value_guides_required: detailGroup.value_guides_required,
              trade_in_required: detailGroup.trade_in_required,
              uuid: detail.id,
            } as SelectOption;
          }),
        };

        tmpOptions.push(newAddCategory);
      }
    }

    return tmpOptions;
  }, [fullCategory, brandId]);

  return (
    <>
      <div className="flex w-full space-x-2">
        <FormikDetailSelectField
          name="category"
          label="Category"
          options={categoryOptions}
          setSelectedCategoryId={setSelectedCategoryId}
          required
        />
      </div>

      <div className="mt-1 mb-4 text-xs italic leading-tight">
        <span>When the toggle</span>
        <span className="text-green-500">
          <CalculatorIcon />
        </span>
        <span>
          is turned ON, that detail will impact the value guide data below.
        </span>
      </div>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        {details.map((detail, index) => {
          const disabled = values.modelVersion?.details?.find(
            d => d.name.toLowerCase() === detail.name.toLowerCase(),
          );

          const detailField = (
            <FormikDetailSelectField
              labelRight={
                <div>
                  <CalculatorCheckbox
                    checked={
                      valueGuideEnabledFields[
                        detail.slug || detail.name.toLowerCase()
                      ] || false
                    }
                    onChange={e =>
                      setValueGuideEnabledFields(v => ({
                        ...v,
                        [detail.slug || detail.name.toLowerCase()]:
                          e.target.checked,
                      }))
                    }
                  />
                </div>
              }
              // formatCreateLabel={input =>
              //   `Create a new ${detail.name} named "${input}"`
              // }
              // createOptionPosition="last"
              // onCreateOption={input => createDetail({ detail, input })}
              key={index}
              disabled={!!disabled}
              name={detail.slug || detail.name}
              label={detail.label}
              options={detail.options}
              isClearable={!detail.required}
              required={detail.required}
            />
          );

          if (detail.name == 'model') {
            return (
              <React.Fragment key={detail.slug || detail.name}>
                {detailField}

                <ModelVersionContainer
                  fullCategory={fullCategory}
                  details={details}
                />
              </React.Fragment>
            );
          }

          return detailField;
        })}
      </div>
    </>
  );
};

function ModelVersionContainer({
  details,
  fullCategory,
}: {
  details: AddCategorySelect[];
  fullCategory: FullCategory | undefined;
}) {
  const { values, setFieldValue } = useFormikContext<CreateItemFormValues>();

  const uuids = Object.entries(values)
    .map(([key, value]) => {
      if (key === 'category' || key === 'model' || key === 'brand') {
        return null;
      }

      const selectOption = value as SelectOption;
      if (selectOption?.uuid && selectOption?.trade_in_required) {
        return selectOption.uuid;
      }
    })
    .filter(Boolean) as string[];

  return (
    <ModelVersionSelector
      fullCategory={fullCategory}
      selectedModelVersionId={values.modelVersionId}
      onChange={newModelVersion => {
        setFieldValue('modelVersionId', newModelVersion?.id);
        setFieldValue('modelVersion', newModelVersion);
        if (!newModelVersion) {
          return;
        }

        const quality = values.quality;
        if (quality) {
          setFieldValue(
            'cashoffer',
            getCashOfferAmountForModelVersionAndQuality(
              quality,
              newModelVersion,
            ),
          );
          setFieldValue(
            'resalePrice',
            getListPriceForModelVersionAndQuality(quality, newModelVersion),
          );
        }

        setFieldValue(
          'retailPrice',
          newModelVersion.price_statistics?.price_retail,
        );

        for (const modelVersionDetailGroup of newModelVersion.details) {
          const firstOption = modelVersionDetailGroup.options?.[0];
          if (!firstOption) {
            continue;
          }

          const newOption = details
            ?.find(d => d.name == modelVersionDetailGroup.name)
            ?.options.find(d => d.uuid == firstOption.id);

          setFieldValue(modelVersionDetailGroup.slug, newOption);
        }
      }}
      hasAModelVersionObjectInState={!!values.modelVersion}
      detailUuids={uuids}
      modelUuid={values.model?.uuid || undefined}
    />
  );
}

export default ItemInfoFields;
