import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

import { FullCategory } from '../../api/intf/item';
import {
  fetchQuoteListingById,
  postListingItem,
  putListingItem,
} from '../../api/item';
import { formatFormikError } from '../../api/ApiError';
import { SelectOption } from '../Form/FormikSelect/DetailSelect';
import { CreateItemFormValues, itemFormSchema } from './CreateItemForm';
import { useEventCustomerQuoteQuery } from '../../hooks/routing/useEventCustomerQuoteQuery';
import { QUALITY_DESCRIPTION } from '../../constants/valueMatrix';
import validateAndGetSubmittableListing from '../../utils/validateAndGetSubmittableListing';
import { TradeInOrganization } from '../../api/orgs';
import FormikDebug from '../Form/FormikDebug';

interface Props extends React.FormHTMLAttributes<HTMLFormElement> {
  categoryItem: FullCategory | undefined;
  customerId: string;
  quoteId: string;
  categoryId: number;
  listingId: any;
  mode: string;
  organization: TradeInOrganization | undefined;
}

const initialValues = {
  category: { value: '', label: '' } as SelectOption,
  grade: { value: '', label: '' } as SelectOption,
  cashoffer: '',
  resalePrice: '',
  retailPrice: '',
};

const EditItemForm: React.FC<Props> = ({
  children,
  categoryItem,
  customerId,
  quoteId,
  categoryId,
  mode,
  listingId,
  organization,
  ...props
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { eventId } = useEventCustomerQuoteQuery();
  const [initValues, setInitValues] = useState<any>(initialValues);

  // Fetch quote by quote id
  const quoteListingQuery = useQuery(
    ['quote-listing', quoteId, listingId],
    () => fetchQuoteListingById(quoteId, listingId),
  );

  const getBrandValue = () => {
    const Item = quoteListingQuery.data;

    if (!categoryItem || !categoryItem.brands || !Item) {
      return {
        label: '',
        value: '',
      };
    }

    if (!Object.keys(Item).includes('brand_id')) {
      return {
        label: '',
        value: '',
      };
    }

    const filteredBrandItem = categoryItem?.brands.find(
      b => b.legacy_id === Item.brand_id,
    );

    if (!filteredBrandItem) {
      return {
        label: '',
        value: '',
      };
    }

    return {
      label: filteredBrandItem.name,
      slug: filteredBrandItem.slug,
      value: `${filteredBrandItem.legacy_id}`,
    };
  };

  const getModelValue = () => {
    const Item = quoteListingQuery.data;

    if (!categoryItem || !categoryItem.models || !Item) {
      return undefined;
    }

    if (!Object.keys(Item).includes('model_id')) {
      return undefined;
    }

    const filteredModelItem = categoryItem?.models.find(
      m => m.legacy_id === Item.model_id,
    );

    if (!filteredModelItem) {
      return {
        label: '',
        value: '',
      };
    }

    return {
      label: filteredModelItem.name,
      slug: filteredModelItem.slug,
      value: String(filteredModelItem.legacy_id),
      uuid: filteredModelItem.id,
    };
  };

  useEffect(() => {
    const listingItem = quoteListingQuery.data;

    if (!listingItem) {
      return;
    }

    let value: CreateItemFormValues = {
      category: {
        label: categoryItem?.full_name || '',
        value: categoryItem?.id ? categoryItem.legacy_id?.toString() : '',
      },
      quality: listingItem.quality,
      cashoffer: String(listingItem.cash_offer_amount || ''),
      resalePrice: String(listingItem.resale_amount || ''),
      retailPrice: String(listingItem.retail_price || ''),
      description: listingItem.description?.replace(
        QUALITY_DESCRIPTION[listingItem.quality!] + '\n\n',
        '',
      ),
      demand: listingItem.demand,
      quantity: listingItem.quantity,
      mpn: listingItem.mpn,
      modelVersionId: listingItem.model_version_id,
    };

    if (getModelValue() !== undefined) {
      value['model'] = getModelValue();
    }

    if (getBrandValue() !== undefined) {
      value['brand'] = getBrandValue();
    }

    if (listingItem.details) {
      for (let i = 0; i < listingItem.details?.length; i += 1) {
        const detail = listingItem.details[i];

        // @ts-expect-error
        if (value[detail.type_slug]) {
          continue;
        }

        // @ts-expect-error
        value[detail.type_slug] = {
          label: detail.option,
          slug: detail.option_slug,
          value: detail.option_id.toString(),
        };
      }
    }

    setInitValues({
      ...value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryItem, quoteListingQuery.data]);

  return (
    <Formik<CreateItemFormValues>
      enableReinitialize
      initialValues={initValues}
      onSubmit={async (values, { setErrors }) => {
        try {
          const requestBody = validateAndGetSubmittableListing(
            values,
            categoryItem!,
            queryClient,
            organization,
          );

          if (mode === 'edit') {
            await putListingItem(quoteId, listingId, requestBody);
          } else {
            await postListingItem(quoteId, requestBody);
          }

          // Invalidate only quotes by ID, then invalidate everything
          await queryClient.invalidateQueries('quotes', {
            exact: false,
          });
          queryClient.invalidateQueries();

          navigate(
            `/event/${eventId}/customer/${customerId}/quote/${quoteId}/summary`,
          );
        } catch (e) {
          setErrors(formatFormikError(e));
        }
      }}
      validationSchema={itemFormSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <Form {...props}>{children}</Form>
    </Formik>
  );
};

export default EditItemForm;
