import React from 'react';
import PageHeader from '../../components/Layout/PageHeader';
import { Button } from '../../components/Form/Button';
import { PrinterIcon } from '@heroicons/react/24/outline';
import PageLayout from '../../components/Layout/PageLayout';
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  fetchPartnerReports,
  postDownloadReport,
  TradeInReport,
} from '../../api/reports';
import { useMutation, useQuery } from 'react-query';
import SimpleReactTable from '../../components/Tables/SimpleReactTable';
import downloadBlob from '../../utils/downloadBlob';
import { format } from 'date-fns';

const columns: ColumnDef<TradeInReport>[] = [
  {
    header: 'Report Created',
    accessorFn: row =>
      format(new Date(row.created_at), "MMMM d, yyyy 'at' h:mm a"),
  },
  {
    id: 'actions',
    header: '',
    cell: ({ row }) => {
      const {
        mutate: downloadDocument,
        isLoading,
        error,
      } = useMutation(async () => {
        const documentForm = await postDownloadReport(row.original.id);
        downloadBlob(documentForm, row.original.filename);
        return documentForm;
      });

      return (
        <Button disabled={isLoading} onClick={() => downloadDocument()}>
          {isLoading ? 'Preparing' : 'Download'}
        </Button>
      );
    },
  },
];

export default function PartnerReportsPage({}: {}) {
  const { data: reports } = useQuery(['reports'], fetchPartnerReports);

  const table = useReactTable({
    columns,
    data: reports || [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <PageLayout
      header={
        <>
          <div className="flex items-center space-x-4">
            <PageHeader className="flex-1">Reports</PageHeader>
          </div>
        </>
      }
    >
      {reports?.length === 0 ? (
        <div className="text-center text-lg font-semibold">No reports</div>
      ) : (
        <SimpleReactTable table={table} />
      )}
    </PageLayout>
  );
}
