import { useFormikContext } from 'formik';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Category, FullCategory } from '../../api/intf/item';
import { fetchValueGuide } from '../../api/item';
import {
  Demand,
  DEMAND_LABELS,
  Quality,
  QUALITY_LABELS,
} from '../../constants/valueMatrix';
import { getResaleValues } from '../../utils/costCalculations';
import displayPrice from '../../utils/displayPrice';
import prepareValuesForValueGuide from '../../utils/prepareValuesForValueGuide';
import FieldError from '../Form/FieldError';
import SimpleSelect from '../Form/Select/SimpleSelect';
import { CreateItemFormValues } from './CreateItemForm';
import ItemInfoFields from './ItemInfoFields';
import ItemDescriptionSection from './ItemDescriptionSection';
import FormikNumberField from '../Form/FormikNumberField';
import SidelineSwapValueGuideDataTable from './SidelineSwapValueGuideDataTable';
import Step from './Step';
import EditableItemTitle from './EditableItemTitle';
import Message from '../Form/Message';
import FormikErrorMessage from '../Form/FormikErrorMessage';
import ItemFormSubmitButtons from './ItemFormSubmitButtons';
import calculateMargin from '../../utils/calculateMargin';
import FieldHint from '../Form/FieldHint';
import { TradeInOrganization } from '../../api/orgs';
import determineShouldBuyItem, {
  doNotBuyLabel,
} from '../../utils/determineShouldBuyItem';
import FormikField from '../Form/FormikField';
import NewTabLink from '../NewTabLink';
import {
  getCashOfferAmountForModelVersionAndQuality,
  getListPriceForModelVersionAndQuality,
} from '../../utils/modelVersionValues';

interface Props {
  categories: Category[] | undefined;
  fullCategory: FullCategory | undefined;
  setSelectedCategoryId: (id: number) => void;
  organization: TradeInOrganization | undefined;
}

const ItemFormFields: React.FC<Props> = props => {
  const { fullCategory } = props;

  const [valueGuideEnabledFields, setValueGuideEnabledFields] = useState<
    Record<string, boolean>
  >({
    category: true,
    brand: true,
    model: true,
  });

  const { values, setFieldValue, setValues, errors } =
    useFormikContext<CreateItemFormValues>();
  const hashedValues = prepareValuesForValueGuide(
    values,
    fullCategory,
    valueGuideEnabledFields,
  );

  const { data: valueGuideData, isLoading } = useQuery(
    ['valueGuide', hashedValues, 'sidelineswap'],
    () => fetchValueGuide(hashedValues, 'sidelineswap'),
    {
      // Use this param to avoid showing "Not enough data" everywhere while it loads
      keepPreviousData: true,
    },
  );
  const { lowResaleValue, midResaleValue, highResaleValue, cashOffer, error } =
    useMemo(
      () =>
        getResaleValues({
          valueGuideResponse: valueGuideData,
          values,
        }),
      [valueGuideData, values],
    );

  const margin = calculateMargin(
    parseFloat(values.cashoffer),
    parseFloat(values.resalePrice),
  );

  let renderedDemandSuggestions: React.ReactNode = null;
  let renderedShouldBuy: React.ReactNode = null;
  if (values.quality && (valueGuideData || values.modelVersion)) {
    const shouldBuy = determineShouldBuyItem(
      values.quality,
      values.valueGuideInput,
      valueGuideData,
      values.modelVersion,
    );
    if (shouldBuy === 'insufficient_sales_data') {
      renderedShouldBuy = (
        <>
          Insufficient Sales Data...{' '}
          <NewTabLink
            href="https://docs.google.com/presentation/d/1TM0GJcC64HgTm8tVTjak7mSJgynaa1rE9R4ld5BvMCs/edit#slide=id.g287d3d2c45b_0_572"
            className="underline"
          >
            Next Steps
          </NewTabLink>
        </>
      );
    } else if (shouldBuy === 'do_not_buy') {
      renderedShouldBuy = doNotBuyLabel(shouldBuy);
    } else if (shouldBuy) {
      renderedDemandSuggestions = doNotBuyLabel(shouldBuy);
    }
  }

  let disablePriceFields = true;
  if (values.quality === 'unacceptable') {
    disablePriceFields = false;
  } else if (values.modelVersionId && values.quality) {
    disablePriceFields = false;
  } else if (values.quality && values.demand && values.valueGuideInput) {
    disablePriceFields = false;
  }

  return (
    <>
      <ItemInfoFields
        {...props}
        brandId={values.brand?.value}
        valueGuideEnabledFields={valueGuideEnabledFields}
        setValueGuideEnabledFields={setValueGuideEnabledFields}
      />

      <EditableItemTitle categoryItem={fullCategory} />

      <div className="mt-4 mb-6">
        <SidelineSwapValueGuideDataTable
          categoryItem={fullCategory}
          isLoading={isLoading}
          valueGuide={valueGuideData}
          valueGuideParameters={hashedValues}
        />
      </div>

      <div className="mb-6 grid grid-cols-2 gap-4 md:grid-cols-3">
        <div>
          <Step>1</Step>
          <FormikNumberField
            label="Value Guide Input"
            name="valueGuideInput"
            prefix="$"
            decimalScale={2}
            changeProperty="floatValue"
            hint={
              values.valueGuideInput ? 'Be sure to review recent sales!' : ''
            }
          />
        </div>

        <div>
          <Step>2</Step>
          <SimpleSelect
            label="Quality"
            error={errors?.quality}
            items={Object.keys(QUALITY_LABELS) as Quality[]}
            onChange={value => {
              const newQuality = value || 'good';
              if (values.modelVersion) {
                setValues({
                  ...values,
                  quality: newQuality,
                  cashoffer: getCashOfferAmountForModelVersionAndQuality(
                    newQuality,
                    values.modelVersion,
                  ).toString(),
                  resalePrice: getListPriceForModelVersionAndQuality(
                    newQuality,
                    values.modelVersion,
                  ).toString(),
                });
              } else {
                setValues({
                  ...values,
                  quality: newQuality,
                  resalePrice: '',
                  cashoffer: '',
                });
              }
            }}
            itemToString={item => item ?? ''}
            renderItem={item => QUALITY_LABELS[item]}
            value={values.quality ?? null}
          />
          {errors?.quality && <FieldError>{errors.quality}</FieldError>}
        </div>

        {!values.modelVersionId && (
          <div>
            <Step>3</Step>
            <SimpleSelect
              label="Demand"
              error={errors?.demand}
              items={['high', 'medium', 'low'] as Demand[]}
              onChange={value => {
                setValues({
                  ...values,
                  demand: value!,
                  resalePrice: '',
                  cashoffer: '',
                });
              }}
              itemToString={item => item ?? ''}
              renderItem={item => DEMAND_LABELS[item]}
              value={values.demand ?? null}
            />
            {renderedDemandSuggestions && (
              <FieldHint>{renderedDemandSuggestions}</FieldHint>
            )}
            {errors?.demand && <FieldError>{errors.demand}</FieldError>}
          </div>
        )}
      </div>

      <div className="mb-6 grid grid-cols-2 gap-4 md:grid-cols-3">
        <div>
          <FormikNumberField
            name="resalePrice"
            label="List Price"
            required
            autoComplete="off"
            prefix="$"
            decimalScale={2}
            changeProperty="floatValue"
            disabled={disablePriceFields}
          />
          <div className="mt-1 text-sm">
            {!!midResaleValue && midResaleValue >= 0 ? (
              <span className="font-semibold">
                Suggested: {displayPrice(midResaleValue)}{' '}
                {lowResaleValue != null &&
                  highResaleValue != null &&
                  `(${displayPrice(lowResaleValue)} - ${displayPrice(
                    highResaleValue,
                  )})`}
              </span>
            ) : (
              ''
            )}
          </div>
        </div>

        <div>
          <FormikNumberField
            name="cashoffer"
            label="Cash Offer"
            required
            autoComplete="off"
            prefix="$"
            decimalScale={2}
            changeProperty="floatValue"
            disabled={disablePriceFields}
          />
          <div className="mt-1 text-sm">
            {displayPrice(cashOffer)} |{' '}
            {margin ? `${margin}% Margin` : '0% Margin'}
          </div>

          {renderedShouldBuy && (
            <FieldHint className="mt-1">{renderedShouldBuy}</FieldHint>
          )}
        </div>

        <div>
          <FormikNumberField
            name="retailPrice"
            label="Retail Price"
            prefix="$"
            decimalScale={2}
            changeProperty="floatValue"
          />
          {valueGuideData?.statistics?.retail_price != null ? (
            <div className="mt-1 text-sm">
              {displayPrice(valueGuideData?.statistics?.retail_price)}
            </div>
          ) : (
            '$--'
          )}
        </div>

        <div>
          <FormikField name="mpn" label="UPC/MPN" autoComplete="off" />
        </div>

        <div>
          <FormikNumberField
            name="quantity"
            label="Quantity"
            autoComplete="off"
          />
        </div>
      </div>

      {!!error && <Message error>{error}</Message>}

      <ItemDescriptionSection />

      <FormikErrorMessage />

      <ItemFormSubmitButtons disabled={!!error} />
    </>
  );
};

export default ItemFormFields;
