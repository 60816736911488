import React from 'react';
import { TradeInModelVersion } from '../../api/intf/item';
import classNames from 'classnames';

interface Props {
  modelVersion: TradeInModelVersion;
  onSelect: (modelVersion: TradeInModelVersion) => void;
  selected?: boolean;
}

const ModelVersionTile: React.FC<Props> = ({
  modelVersion,
  onSelect,
  selected,
}) => {
  return (
    <button
      key={modelVersion.id}
      type="button"
      className={classNames(
        'rounded border-2 border-transparent flex flex-col px-3 py-2 hover:border-grey-500',
        selected ? 'border-green-500' : 'border-transparent',
      )}
      onClick={() => onSelect(modelVersion)}
    >
      <div className="mb-4 aspect-w-9 aspect-h-11">
        <img src={modelVersion.primary_image?.small_url} />
      </div>

      <div className="font-semibold w-full flex-1">{modelVersion.name}</div>
      {modelVersion.sku && (
        <div className="text-grey-500 mt-2 w-full text-center text-sm">
          SKU: {modelVersion.sku}
        </div>
      )}
    </button>
  );
};

export default ModelVersionTile;
