import { TradeInOptionMap, TradeInSelection } from '../types/detailSelection';
import React from 'react';

const hockey =
  /Hockey( > Skates( > .*)?)?|Hockey( > Goalie( > Goalie Skates)?)?/;
const baseball = /Baseball( > Bats)?|Softball( > Bats)?/;
const golf = /Golf( > Clubs( > .*)?)?/;

function setCategories(categories: RegExp[]): RegExp {
  return new RegExp('^(' + categories.map(c => c.source).join('|') + ')$');
}

export const ALLOWED_CATEGORIES_MAP_RE: Record<string, RegExp> = {
  DEFAULT: setCategories([baseball]),

  // SidelineSwap Engineering Store #1
  'f7a4fba2-ab35-4490-a5c7-c8ff755077e8': setCategories([
    baseball,
    hockey,
    golf,
  ]),

  // Perani's Hockey World in prod
  '3bfe72ee-e7bf-4b3f-9c8e-9b14d6fefff3': setCategories([hockey]),

  // MonkeySports in prod
  '4c2bc86f-9dd7-477d-84b3-1442fe6f431a': setCategories([baseball, hockey]),

  // sports-exchange3 in staging (trade-in iam user in 1pass)
  '6de8b6dd-ba85-4a08-a9f4-3a9c5e6c1763': setCategories([baseball, hockey]),

  // Boch
  '32921d2f-4ea0-4c68-ae3d-fd766f110299': setCategories([
    baseball,
    hockey,
    golf,
  ]),

  // USSSA Space Coast Complex
  'f79ca231-a2d0-460f-b352-d751065f481f': setCategories([baseball]),

  // Oak Tree Sports Center d.b.a. BB Grandslam
  '4987177a-c520-48e4-86b5-3ee9144494bb': setCategories([
    baseball,
    hockey,
    golf,
  ]),

  // CloseOut Bats
  '69b546e7-38e9-4057-900d-150a28d66d56': setCategories([baseball]),

  // Ko Sports
  '2d7d923b-8737-4e73-a163-6d8073a2287a': setCategories([hockey]),

  // XHockeyProducts
  '96ab653e-bc64-4068-8412-9bfd174feaab': setCategories([hockey]),

  // Goal Line Pro Shop
  '4c398e4f-f117-4c22-8c26-f2aa16bf1d0c': setCategories([hockey]),

  // Sports Etc
  '4ea59d2e-aa27-470c-9bf4-806bcd9bdc93': setCategories([hockey]),
};

const GOALIE_SKATES = 'hockey/goalie/goalie-skates';
const HOCKEY_SKATES = 'hockey/skates';

export function getRenderOptionSubtitle(
  options: TradeInOptionMap,
  selection: TradeInSelection,
): string | undefined {
  const category =
    options?.categories?.[options?.categories?.length - 1]?.selected;

  if (category?.slug === HOCKEY_SKATES || category?.slug === GOALIE_SKATES) {
    switch (selection.name) {
      case 'Senior':
        return '(Sizes 7 & up)';

      case 'Junior':
        return '(Sizes 1 - 3.5)';

      case 'Intermediate':
        return '(Sizes 4 - 6.5)';

      case 'Youth':
        return '(Sizes y06 - y13.5)';
    }
  }

  return undefined;
}

export function CategoryConditionCopy({
  categorySlug,
}: {
  categorySlug?: string;
}) {
  if (categorySlug === HOCKEY_SKATES || categorySlug === GOALIE_SKATES) {
    return (
      <>
        <li>Blade holder, boot, and steel cannot have any cracks</li>
        <li>Skates must have 1/2" of blade</li>
        <li>Skates must have insoles</li>
        <li>Skates cannot have any mold or mildew</li>
        <li>Skates must have laces in good condition</li>
        <li>
          Achilles guard should not be able to be bent &gt;45 degrees back
        </li>
        <li>Skates must have all rivets attaching the blade holder to boot</li>
        <li>Eyelets must not be ripped or broken</li>
        <li>Steel cannot have excessive rust</li>
      </>
    );
  }

  return (
    <>
      <li>
        There are no <b>dents</b> larger than a nickel
      </li>
      <li>
        There are no <b>cracks or webbing</b> anywhere on the bat
      </li>
      <li>
        The bat is not <b>bent</b>
      </li>
      <li>
        The <b>end cap</b> is not broken or loose
      </li>
      <li>
        The <b>grip tape</b> does not need to be replaced
      </li>
      <li>
        The bat does not <b>vibrate or rattle</b> when shaken
      </li>
    </>
  );
}
